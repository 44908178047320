import createSvgIcon from "@material-ui/core/utils/createSvgIcon";

const SwitchboardIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720 720">
    <path
      d="m647.76979 144.283h-345.59865l-1.24959-.12421c-.694-.06625-76.63372-6.61815-123.706 36.259-25.73393 23.4401-38.78224 58.48515-38.78224 104.163 0 45.54862 12.96965 80.274 38.54872 103.20733 46.9274 42.07393 122.85061 34.31135 123.61 34.23351l1.37878-.15071 1.455-.00828h33.8a56.8504 56.8504 0 1 1 -.819 50.87825h-31.70581c-3.05568.28486-9.95621.80657-19.5025.80657-32.38272 0-95.22614-6.002-141.87116-47.60065-37.00766-33.00464-55.77234-80.56719-55.77234-141.36604 0-60.77235 18.74563-108.57009 55.71682-142.06494 60.57858-54.88292 148.56633-50.123 161.24448-49.11109h296.97884a358.38943 358.38943 0 0 0 -241.81103-93.40474c-198.65747 0-359.70155 161.04408-359.70155 359.70155a358.10233 358.10233 0 0 0 71.63118 215.43845h344.79581l1.20654.11593c.71382.06294 78.40584 6.54693 125.35311-36.3435 24.46529-22.35033 36.87018-55.35329 36.87018-98.08969 0-42.61551-12.33036-75.317-36.64907-97.19867-46.80237-42.11368-124.47534-34.4455-125.25292-34.35772l-1.33489.14077-1.40528.00663h-26.43285a56.84974 56.84974 0 1 1 .791-50.87826h24.40633c13.36382-1.21233 102.64919-6.99907 162.976 47.0127 35.686 31.94962 53.7799 77.46181 53.7799 135.27455 0 57.78292-18.074 103.51207-53.72111 135.91714-48.59767 44.1773-116.05111 49.8067-147.25876 49.80835-7.64912 0-13.11622-.33786-15.59968-.53h-296.24282a358.3885 358.3885 0 0 0 241.78888 93.38528c198.65788 0 359.70175-161.04408 359.70175-359.702a358.102 358.102 0 0 0 -71.61607-215.41851z"
      fill="#23272A"
    />
  </svg>,
  "Discord"
);

export default SwitchboardIcon;
